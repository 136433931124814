
export default function slugSolutions() {
	const url = window.location.href;
	const urlObject = new URL(url);
	// const pathname = urlObject.pathname;
	// const parts = pathname.split('/');
	// const slug = parts.pop() || parts.pop(); // O segundo pop é para lidar com uma barra final opcional
	const titlePage = document.querySelector('[data-title]')

	document.title = `${document.title} ${titlePage.dataset.title}`
}