import Swiper from "swiper"
import { find } from "utils/dom"
import initTabs from "../modules/tabs"

export default function () {
	const Cases = find("[js-page='cases']")
	if (!Cases) return


	const swiperClientes = new Swiper(".Swiper-clientes", {

		spaceBetween: 8,
		autoplay: {
			delay: 3000,
		},



		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
		breakpoints: {
			320: {
				slidesPerView: "auto",


			},
			1024: {
				slidesPerView: "auto",
				spaceBetween: 56,
				centeredSlides: false,
			},
		},


	})



}

initTabs(".tab-item", ".tab-content", ".tabs")