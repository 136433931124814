import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageCases from './pages/Cases.js'
import PageLogin from "./pages/Login";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import contatoForm from "./contato-form.js";
import contatoFormAtas from "./contato-form-atas";
import anchorScroll from './animation/anchorScroll';
import slugSolutions from './add_slug_solucoes_single';

// import SvgUse from "./utils/SvgUse";

const pageAtas = document.querySelector("[js-page='atas']")
const pageSolucoesSinge = document.querySelector("[js-page='solucoes_single']")
// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()
anchorScroll()
contatoForm();


// Pages
PageHome()
PageCases()
PageLogin()?.init()

if(pageAtas){
    contatoFormAtas()
}

if(pageSolucoesSinge){
    slugSolutions()
}

// utils logs
logs()

