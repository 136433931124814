import axios from 'axios'
import showToastify from './utils/toastify';
import { style } from './utils/dom';
import IMask from 'imask'

export default function contatoFormAtas() {
	const contatoFormSubmit = document.querySelectorAll('#form-contato-atas')

	function checkIfHasEmptyValues(inputs) {
		Object.values(inputs).forEach(input => {
			console.log(input);
			if (!input.value)
				showToastify('Preencha todos os campos!')
			throw new Error('Preencha todos os campos')
		});
	}

	const element = document.querySelector('#form-contato-atas input[name=telefone]')
	const maskOptions = {
		mask: [
			{
				mask: '(00) 0000-0000',
			},
			{
				mask: '(00) 00000-0000',
			},
		],
	}
	const mask = IMask(element, maskOptions)

	contatoFormSubmit.forEach(form => {

		form && form.addEventListener('submit', async function (event) {
			event.preventDefault();
			disabledBtn()
			const form_data = {
				nome: document.querySelector('#form-contato-atas input[name=nome]').value,
				email: document.querySelector('#form-contato-atas input[name=email]').value,
				telefone: document.querySelector('#form-contato-atas input[name=telefone]').value,
				estado: document.querySelector('#form-contato-atas select[name=estado]').value,
				tecnologia: document.querySelector('#form-contato-atas select[name=tecnologia]').value
			}


			const url = "/registros-atas/"
			axios.post(url, form_data)
				.then((response) => showMessage(response, form))
				.catch((response) => showMessage('Erro ao enviar mensagem', form));
		});
	});

	function showMessage(response, el) {
		const message = response.data ? response.data.message : response.message
		showToastify(message)
		el.reset()
		setTimeout(enabledBtn, 1000)

	}

	const btnSubmit = document.querySelector("#form-contato-atas .btn-send[type=submit]")
	console.log(btnSubmit);

	function disabledBtn() {
		btnSubmit.disabled = true
		btnSubmit.classList.add("disabled")
	}

	function enabledBtn() {
		btnSubmit.disabled = false
		btnSubmit.classList.remove("disabled")
	}
}