import gsap from "gsap";

export default function initTabs(tabsListEl, tabsContentEl, tabsContentWrapperEl, tabContentImageEl = null) {


	const tabs = document.querySelectorAll(tabsListEl);
	const tabsContent = document.querySelectorAll(tabsContentEl);
	const tabContentWrapper = document.querySelector(tabsContentWrapperEl);
	const tabContentImage = document.querySelector(`${tabContentImageEl} img`);
	const closestSvg = document.querySelector(`${tabContentImageEl} svg`);
	const isMobile = window.matchMedia("(max-width: 1024px)").matches;

	const tl = gsap.timeline({
		defaults: {
			duration: 0.4,
			ease: "power2.inOut"

		}
	});

	function initAnimation() {
		tl.fromTo(closestSvg, {
			opacity: 0,
			rotate: 180
		}, {
			opacity: 1,
			rotate: 0,

		})
			.fromTo(tabContentImage, {
				scale: 0.8,
				opacity: 0
			}, {
				opacity: 1,
				scale: 1,
			},
			)
			.fromTo(`.tab-content.active h2`, {
				opacity: 0,
				x: 30
			}, {
				opacity: 1,
				x: 0,

			})
			.fromTo(`.tab-content.active p`, {
				opacity: 0,
				y: 30
			}, {
				opacity: 1,
				y: 0,

			})
			.fromTo(`.tab-content.active li`, {
				opacity: 0,
				y: 30
			}, {
				opacity: 1,
				y: 0,

			})
			.fromTo(`.tab-content.active a`, {
				opacity: 0,
				y: 30
			}, {
				opacity: 1,
				y: 0,

			});
	}






	function setImageSrc(tab) {
		if (tabContentImage) {

			tabContentImage.src = tab.dataset.image;
		}
	}

	function handleEvent(tab) {
		return function () {
			tabs.forEach((tab) => tab.classList.remove("active"));
			tab.classList.add("active");
			tabsContent.forEach((content) => content.classList.remove("active"));
			console.log(tabsContent, tab.dataset.index);
			tabsContent[tab.dataset.index]?.classList.add("active");
			if (tabContentImage) {
				setImageSrc(tabsContent[tab.dataset.index]);
			}
			if (isMobile)
				tabContentWrapper?.scrollIntoView({ behavior: "smooth" });

			if (tabsContent[tab.dataset.index].classList.contains('active')) initAnimation();

		}

	}

	tabs.forEach((tab) => {
		tab.addEventListener("click", handleEvent(tab));
	});




}